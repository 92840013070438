$color-black: #1a1a1a;
$color-grey50: #ecedef;
$color-grey100: #dadbdf;
$color-grey300: #8f949e;
$color-grey500: #72767E;
$color-grey600: #4b4b4b;
$color-grey: #454d5d;
$color-blue800: #011321;
$color-blue: #07244b;
$color-blue200: #9ca7b7;
$color-white: #ffffff;
$color-red: #e11e1d;
$color-gold: #fec53d;

$font-size-xxs: 0.7rem;
$font-size-xs: 0.8rem;
$font-size-s: 0.9rem;
$font-size-m: 1rem;
$font-size-l: 1.2rem;
$font-size-xl: 1.4rem;
$font-size-xxl: 1.6rem;
$font-size-xxxl: 1.8rem;

$font-weight-semibold: 600;
$font-weight-regular: 500;

$gap-xxs: 2px;
$gap-xs: 4px;
$gap-s: 8px;
$gap-m: 16px;
$gap-l: 32px;
$gap-xl: 64px;

$breaking-point-mobile-tablet: 600px;
$breaking-point-tablet-desktop: 768px;
$breaking-point-desktop-large-desktop: 1280px;

$animation-duration-default: 200ms;
